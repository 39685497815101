import React from "react";
import { EvidencePageResponseInfo } from "../../../api";
import { Loading } from "../../../components";
import "./evidence-content.scss";
import { isEmptyObject } from "../../../utils";
import { EvidenceViewerDefault } from "./EvidenceContentDefault";

interface Props {
  pageInfo: EvidencePageResponseInfo;
  loading: boolean;
  searchKeywords: string[];
  selectedLegends: string[];
  highlightCount: number
}

export const EvidenceContent = ({ pageInfo, loading, searchKeywords, selectedLegends,highlightCount }: Props) => {
  return (
    <>
      {isEmptyObject(pageInfo) || loading ? (
        <Loading />
      ) : (
        <div className="evidence-content">
          <EvidenceViewerDefault {...{ pageInfo, searchKeywords, selectedLegends , highlightCount}} />
        </div>
      )}
    </>
  );
};
