import React, { useEffect, useState } from "react";
import "./patient-summary.scss";
import { Button, Collapse, Flex, Dropdown, Menu, Modal } from "antd";
import {
  CopyOutlined,
  DislikeOutlined,
  DownCircleOutlined,
  DownloadOutlined,
  LikeOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import { DiagAndProcCodes, patientSummaryService } from "../../api";
import { ReactTyped } from "react-typed";
import {
  handleCopyToClipboard,
  handleDocxDownload,
  handlePdfDownload,
  MODAL_TYPE,
  openNotificationWithIcon,
} from "../../utils";
import { Loading } from "../../components";
import AskAuto from "../../components/ask-auto/AskAuto";
import { CodesPopup } from "./CodesPopup";
import {
  extractValues,
  extractValuesSummary,
  nestedValues,
  shortFormSummary,
} from "../case-details-container/constants";
import { useDispatch } from "react-redux";
import { openModal, setFeedbackType } from "../../store";
import RadioToggle from "../../components/radio-toggle/RadioToggle";
import { RadioChangeEvent } from "antd/lib";
import Questionnaire from "../questionnaire/Questionnaire";
import Markdown from "react-markdown";

export type ProcedureProps = {
  procedureCode?: string;
  procedureDescription?: string;
};

export type DiagnosisProps = {
  diagnosisCode?: string;
  diagnosisDescription?: string;
};

type Props = {
  enableNextTab: (procedureCode: ProcedureProps, diagnosisCode: DiagnosisProps) => void;
  documentUUID: string;
  projectId: number;
  documentName: string;
};

interface Procedure {
  procedureCode: string;
  dateOfServiceStart: string;
  dateOfServiceEnd: string;
  procedureDescription: string;
}

interface Diagnosis {
  diagnosisCode: string;
  diagnosisQualifier: string;
  diagnosisDescription: string;
}

interface PatientData {
  acuity: string;
  admissionDateTime: string;
  authorizationID: string;
  dateOfBirth: string;
  dischargeDateTime: string;
  memberID: string;
  memberName: string;
  placeOfService: string;
  requestingProviderName: string;
  requestingProviderFaxNo: string;
  requestingProviderNPI: string;
  servicingProviderName: string;
  servicingProviderNPI: string;
  typeOfService: string;
  state: string;
  procedures: Procedure[];
  diagnoses: Diagnosis[];
  [key: string]: string | any;
}

const documentsWithHardCodedValues = [
  "PBMCase1.pdf",
  "PBMCase2.pdf",
  "PBMCase3.pdf",
  "PBMCase4.pdf",
];

export const PatientSummaryNew = ({
  enableNextTab,
  documentUUID,
  projectId,
  documentName,
}: Props) => {
  const [showPatientSummary, setShowPatientSummary] = useState(false);
  const [expandPatientData, setExpandPatientData] = useState(true);
  const [showCodePopup, setShowCodePopup] = useState(false);
  const [selectedProcedureCode, setSelectedProcedureCode] = useState<ProcedureProps>({
    procedureCode: "",
    procedureDescription: "",
  });
  const [selectedDiagnosisCode, setSelectedDiagnosisCode] = useState<DiagnosisProps>({
    diagnosisCode: "",
    diagnosisDescription: "",
  });
  const [patientSummary, setPatientSummary] = useState(``);
  const [shortSummary,setShortSummary] = useState(``);
  const [caseInfo, setCaseInfo] = useState(extractValues[documentName]);
  const [diagAndProcCodes, setDiagAndProcCodes] = useState([] as DiagAndProcCodes[]);
  const [timeToProcess, setTimeToProcess] = useState<number>(0);
  const [extractedFieldCount, setExtractedFieldCount] = useState<number>(0);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [selectedForm, setSelectedForm] = useState<string>("long");

  const dispatch = useDispatch();
  const getCaseInfo = async () => {
    try {
      const { data } = await patientSummaryService.getCaseInfo(documentUUID);
      setDiagAndProcCodes(data.data.diagAndProcCodes);
      setTimeToProcess(data.data.timeToProcess);
      setExtractedFieldCount(data.data.extractedFieldCount);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  useEffect(() => {
    getCaseInfo();
  }, []);

  const handleClose = () => {
    setShowCodePopup(false);
  };

  const handleConfirmCode = async (diagnoses?: DiagnosisProps, procedure?: ProcedureProps) => {
    try {
      handleClose();
      setIsSummaryLoading(true);
      const { data } = await patientSummaryService.generatePatientSummary(
        documentUUID,
        diagnoses ? diagnoses : selectedDiagnosisCode,
        procedure ? procedure : selectedProcedureCode
      );
      setShowPatientSummary(true);
      setExpandPatientData(false);
      setIsSummaryLoading(false);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  const handleGeneratePatientSummary = () => {
    setIsSummaryLoading(true);
    setPatientSummary(extractValuesSummary[documentName]);
    shortFormSummary[documentName] ? setShortSummary(shortFormSummary[documentName]) : setShortSummary("")
    setShowPatientSummary(true);
    setExpandPatientData(false);
    setIsSummaryLoading(false);
  };

  const handleGenerateCompliance = () => {
    enableNextTab(selectedProcedureCode, selectedDiagnosisCode);
  };

  const formatKey = (key: string) => {
    const words = key.split(/(?=[A-Z])/);
    const formattedKey = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return formattedKey;
  };

  const handleThumbsClick = (type: "up" | "down") => {
    // dispatch(
    //   setQuestionAnswerLogId({
    //     logId: item.logId,
    //     qaId: item.qaId,
    //   })
    // );
    dispatch(setFeedbackType(type));
    dispatch(openModal(MODAL_TYPE.FEEDBACK));
  };

  const exportMenu = (
    <Menu>
      <Menu.Item
        key="pdf"
        icon={<DownloadOutlined />}
        className="export-dropdown-item"
        onClick={() => handlePdfDownload(patientSummary, "Patient Summary", documentName)}
      >
        Download as PDF
      </Menu.Item>
      <Menu.Item
        key="docx"
        icon={<DownloadOutlined />}
        className="export-dropdown-item"
        onClick={() => handleDocxDownload(patientSummary, "Patient Summary", documentName)}
      >
        Download as Docx
      </Menu.Item>
      <Menu.Item
        key="copy"
        icon={<CopyOutlined />}
        className="export-dropdown-item"
        onClick={() => handleCopyToClipboard(patientSummary, "Patient Summary")}
      >
        Copy to Clipboard
      </Menu.Item>
    </Menu>
  );

  const radioOptions = [
    { label: "Long Form", value: "long" },
    { label: "Short Form", value: "short" },
  ];

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedForm(e.target.value);
  };

  const renderValue = (value: any) => {
    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      return (
        <div className="nested-object">
          {Object.keys(value).map((nestedKey) => (
            <div className="nested-item" key={nestedKey}>
              <span className="nested-key">{nestedKey}:</span>
              <span className="nested-value">{value[nestedKey]}</span>
            </div>
          ))}
        </div>
      );
    } else if (Array.isArray(value)) {
      return (
        <div className="nested-array">
          {value.map((item, index) => (
            <div key={index} className="nested-array-item">
              {Object.keys(item).map((nestedKey) => (
                <div key={nestedKey}>
                  <span className="nested-key">{`${nestedKey}: `}</span>
                  <span className="nested-value">{item[nestedKey]}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    }
    return <span>{value}</span>;
  };

  const renderNestedValues = () => {
    const data = nestedValues[documentName];

    if (!data) {
      return null;
    }

    return (
      <div className="single-info grid grid-cols-2">
        {Object.keys(data).map((key) => (
          <React.Fragment key={key}>
            <div className="patientData-info">{key}</div>
            <div className="fz-16 p-block">{renderValue(data[key])}</div>
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="patientData">
        <div className="patientData-info">
          {`Time to process: ${(
            timeToProcess / 1000
          ).toFixed()} secs  |  Extracted fields count:  ${
            extractedFieldCount == 0 && caseInfo
              ? Object.keys(caseInfo).length
              : extractedFieldCount
          }`}
        </div>
        <div
          className={`patientData-patientInfo  ${
            patientSummary
              ? `patientData-patientInfo-${expandPatientData ? "expanded" : "short"}`
              : "show-full"
          }`}
        >
          {documentName === "Billing Document - 8007307259.pdf" ||
          documentName === "Inv_31289_from_SILICONES_PLUS_INC._1412.pdf" ||
          documentName === "Inv_65375_from_Exakt_Technologies_Inc_10832.pdf" ||
          documentName === "Invoice-2024-83163-00.pdf" ? (
            <div>{renderNestedValues()}</div>
          ) : (
            Object.keys(caseInfo).length > 0 && (
              <div className="single-info grid grid-cols-2">
                <div className="patientData-info">{Object.keys(caseInfo)[0]}</div>
                <div className="fz-16 p-block">
                  {caseInfo[`Member Name`] ||
                    caseInfo[`Date of Birth`] ||
                    caseInfo[`Segment`] ||
                    caseInfo[`Supplier Name`]}
                </div>
              </div>
            )
          )}

          {expandPatientData && (
            <>
              {caseInfo &&
                Object.entries(caseInfo).map(([key, value], index) => {
                  if (key !== Object.keys(caseInfo)[0] && value) {
                    return (
                      <div key={index}>
                        {key !== "Line Item Information" && (
                          <div className="single-info grid grid-cols-2">
                            <div className="patientData-info">{key}</div>
                            <div className="fz-16 p-block">{value}</div>
                          </div>
                        )}
                      </div>
                    );
                  } else {
                    return null; // Skip rendering if value is empty
                  }
                })}
            </>
          )}
        </div>

        {!expandPatientData ? (
          <div className="patientData-downicon">
            <DownCircleOutlined
              onClick={() => {
                setExpandPatientData(!expandPatientData);
              }}
            />
          </div>
        ) : (
          <div className="patientData-upicon">
            <UpCircleOutlined
              onClick={() => {
                setExpandPatientData(!expandPatientData);
              }}
            />
          </div>
        )}
        {showPatientSummary ? (
          isSummaryLoading ? (
            <Loading />
          ) : (
            <div>
              <div style={{ marginBottom: "16px" }}>
                <Collapse expandIconPosition="right" defaultActiveKey={["1"]} accordion>
                  <Collapse.Panel 
                    header={
                      <h2
                        style={{
                          fontSize: "15px",
                          padding: "2px 8px",
                          margin: 0,
                        }}
                        className="patientData-info"
                      >
                        Patient Summary{" "}
                      </h2>
                    }
                    key="1"
                  >
                    <Flex justify="end" className="p-10">
                      {/* <h2 className="patientData-info">Patient Summary</h2> */}
                      <div className="radio-wrapper">
                        <RadioToggle
                          options={radioOptions}
                          value={selectedForm}
                          onChange={handleRadioChange}
                        />
                      </div>
                    </Flex>
                    <div
      style={{
        maxHeight: '200px', 
        overflowY: 'auto',
        fontSize: '16px',
      }}
    >
      {selectedForm === "short" ? (
        <ReactTyped
          strings={[shortSummary.replace(/&/g, "&amp;")]}
          typeSpeed={1}
          showCursor={false}
        />
      ) : (
        <ReactTyped
          strings={[patientSummary.replace(/&/g, "&amp;")]}
          typeSpeed={1}
          showCursor={false}
        />
      )}
    </div>
                  </Collapse.Panel>
                </Collapse>
              </div>{" "}
              <div style={{ marginBottom: "16px" }}>
                <Collapse expandIconPosition="right" accordion>
                  <Collapse.Panel
                    header={
                      <h2
                        style={{
                          fontSize: "15px",
                          padding: "2px 8px",
                          margin: 0,
                        }}
                      >
                        Questionnaire{" "}
                      </h2>
                    }
                    key="1"
                  >
                    <Questionnaire />
                  </Collapse.Panel>
                </Collapse>
              </div>
            </div>
          )
        ) : (
          <div className="patientData-actions">
            <div className="actions">
              <AskAuto projectId={projectId} documentUuid={documentUUID} />
              {/* <LikeOutlined
                className="cursor-pointer flex ask-auto-button"
                onClick={() => handleThumbsClick("up")}
              />
              <DislikeOutlined
                className="cursor-pointer flex ask-auto-button"
                onClick={() => handleThumbsClick("down")}
              /> */}{" "}
            </div>
            <Button
              className="fill"
              style={{ float: "right" }}
              onClick={handleGeneratePatientSummary}
              disabled={!extractValuesSummary[documentName]}
            >
              Generate Patient Summary
            </Button>
          </div>
        )}

        {showPatientSummary && (
          <div 
          className="patientData-footer" 
          style={{
            position: "fixed",
            bottom: "0",
            right: "0",
            width: "57.3%",
            height: "7%",
            padding: "35px 20px",
            backgroundColor: "#f8f9fa",
            marginBottom: "6px"
          }}
        >
            <div className="actions">
              <AskAuto buttonClassName="action" documentUuid={documentUUID} projectId={projectId} />
              {/* <LikeOutlined className="cursor-pointer flex action" />
              <DislikeOutlined className="cursor-pointer flex ask-auto-button"/>{" "} */}
            </div>
            <div className="flex gp">
              <Dropdown overlay={exportMenu} placement="topCenter" trigger={["click"]}>
                <Button className="outline">Export Summary as</Button>
              </Dropdown>
              <Button
                className="fill"
                onClick={handleGenerateCompliance}
                disabled={documentsWithHardCodedValues.includes(documentName)}
              >
                Generate Guideline Compliance
              </Button>
            </div>
          </div>
        )}
      </div>
      {showCodePopup && (
        <CodesPopup
          {...{
            showCodePopup,
            setShowCodePopup,
            diagAndProcCodes,
            setSelectedProcedureCode,
            setSelectedDiagnosisCode,
            handleConfirmCode,
          }}
        />
      )}
    </>
  );
};

export default PatientSummaryNew;
