import React, { useState } from "react";
import { Radio, Button, Card, Space, Typography } from "antd";
import { openNotificationWithIcon } from "../../utils";

const { Title } = Typography;

const Questionnaire = () => {
  const initialQuestions = [
    {
      id: 1,
      question: "Will the requested drug be used with a reduced calorie diet and increased physical activity for chronic weight management?",
      options: ["Yes", "No"],
      answer: "Yes"
    },
    {
      id: 2,
      question: "Is the patient 18 years of age or older?",
      options: ["Yes", "No"],
      answer: "Yes"
    },
    {
      id: 3,
      question: "Has the patient completed at least 3 months of therapy with the requested drug at a stable maintenance dose?",
      options: ["Yes", "No"],
      answer: "No"
    },
    {
      id: 4,
      question: "Has the patient lost at least 5 percent of baseline body weight OR has the patient continued to maintain their initial 5 percent weight loss?",
      options: ["Yes", "No"],
      answer: "No"
    },
    {
      id: 5,
      question: "Has documentation of the patient's weight prior to starting Wegovy therapy and the patient's current weight after Wegovy therapy, including the date the weights were taken been submitted?",
      options: ["Yes", "No"],
      answer: "No"
    },
    {
      id: 6,
      question: "Has the patient participated in a comprehensive weight management program that encourages behavioral modification, reduced calorie diet, and increased physical activity with continuing follow-up for at least 6 months prior to using drug therapy?",
      options: ["Yes", "No"],
      answer: "No"
    },
    {
      id: 7,
      question: "Does the patient have a body mass index (BMI) of less than 27 kg/m2?",
      options: ["Yes", "No"],
      answer: "No"
    },
    {
      id: 8,
      question: "Does the patient have a body mass index (BMI) of 27 kg/m2 to less than 30 kg/m2?",
      options: ["Yes", "No"],
      answer: "No"
    },
    {
      id: 9,
      question: "Have chart notes showing the patient's current body mass index (BMI) been submitted?",
      options: ["Yes", "No"],
      answer: "Yes"
    },
    {
      id: 10,
      question: "Does the patient have at least one weight-related comorbid condition (e.g., hypertension, type 2 diabetes mellitus, dyslipidemia)?",
      options: ["Yes", "No"],
      answer: "Yes"
    },
    {
      id: 11,
      question: "Have chart notes indicating the patient's current weight-related comorbid condition(s) been submitted?",
      options: ["Yes", "No"],
      answer: "Yes"
    },
    {
      id: 12,
      question: "Does the patient have a body mass index (BMI) of 30 kg/m2 to less than 35 kg/m2?",
      options: ["Yes", "No"],
      answer: "Yes"
    },
    {
      id: 13,
      question: "Does the patient have a body mass index (BMI) of 35 kg/m2 to less than 40 kg/m2?",
      options: ["Yes", "No"],
      answer: "No"
    },
    {
      id: 14,
      question: "Does the patient have a body mass index (BMI) of 40 kg/m2 or greater?",
      options: ["Yes", "No"],
      answer: "No"
    },
    {
      id: 15,
      question: "Is the patient 12 to 17 years of age?",
      options: ["Yes", "No"],
      answer: "No"
    },
    {
      id: 16,
      question: "Is the request for continuation of therapy for a patient that has successfully titrated to a stable maintenance dose?",
      options: ["Yes", "No"],
      answer: "Yes"
    },
    {
      id: 17,
      question: "Does the patient have an initial body mass index (BMI) in the 95th percentile or greater standardized for age and sex (obesity)?",
      options: ["Yes", "No"],
      answer: "No"
    },
    {
      id: 18,
      question: "Have chart notes showing the patient's current body mass index (BMI) percentile standardized for age and sex been submitted?",
      options: ["Yes", "No"],
      answer: "No"
    }
  ];
  

  const [questions, setQuestions] = useState<any>(initialQuestions);
  const [isEditMode, setIsEditMode] = useState(false);
  const [tempAnswers, setTempAnswers] = useState<any>({});

  const handleEdit = () => {
    setIsEditMode(true);
    const currentAnswers :any= {};
    questions.forEach((q:any) => {
      currentAnswers[q.id] = q.answer;
    });
    setTempAnswers(currentAnswers);
  };

  const handleSave = () => {
    const updatedQuestions = questions.map((q:any) => ({
      ...q,
      answer: tempAnswers[q.id] || q.answer
    }));
    setQuestions(updatedQuestions);
    setIsEditMode(false);
    openNotificationWithIcon("", `Successfully saved responses`, "success");
  };

  const handleAnswerChange = (questionId:any, value:any) => {
    setTempAnswers((prev:any) => ({
      ...prev,
      [questionId]: value
    }));
  };

  return (
    <div style={{ maxWidth: 800, margin: '0 auto', padding: 24 }}>
      <h2 className="patientData-info">QA Responses:</h2>
      <Space direction="vertical" size={16} style={{ width: '100%' }}>
        {questions.map((q:any) => (
          <Card key={q.id}>
            <h5 style={{padding:"5px",font:"Helvetica Neue"}}>{q.question}</h5>
            <Radio.Group
              value={isEditMode ? tempAnswers[q.id] || q.answer : q.answer}
              onChange={(e) =>isEditMode ? handleAnswerChange(q.id, e.target.value):""}
              style={isEditMode ? {padding:"5px"}: {padding:"5px",pointerEvents:"none"}}
            >
              <Space direction="vertical">
                {q.options.map((option:any) => (
                  <Radio key={option} value={option}>
                    {option}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Card>
        ))}
      </Space>

      <div style={{ marginTop: 24 }}>
        {!isEditMode ? (
          <Button className="fill" onClick={handleEdit}>
            Edit Answers
          </Button>
        ) : (
          <Space>
            <Button className="fill" onClick={handleSave}>
              Save Changes
            </Button>
            <Button className="outline" onClick={() => setIsEditMode(false)}>
              Cancel
            </Button>
          </Space>
        )}
      </div>
    </div>
  );
};

export default Questionnaire;
