import React, { useEffect, useState } from "react";
import { FileUploader, UploadedFilesFolders } from "../../components";
import { projectService, uploadFileFolderService } from "../../api";
import { useParams } from "react-router-dom";
import { ProjectDetails } from "../../components/project-details/ProjectDetails";

// interface Params {
//   projectId: string; // Define projectId as a string
// }

export const CopilotContainer = () => {
  // const [projectId, setProjectId] = useState<number>();
  const [loading, setLoading] = useState(false);
  // const getProjectId = async () => {
  //   const { data } = await projectService.getProjects();
  //   setProjectId(data.data[0]?.id);
  // };
  // useEffect(() => {
  //   getProjectId();

  // }, []);
  const { projectId } = useParams();
  const projectIdNumber = Number(projectId); //Converted to number to match the api req

  return (
    <div>
      <ProjectDetails />
      <FileUploader {...{ projectId: projectIdNumber, loading, setLoading }} />
      <UploadedFilesFolders {...{ projectId: projectIdNumber, loading}} />
    </div>
  );
};

export default CopilotContainer;
