import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import "./evidence-viewer-container/evidence-viewer-container.scss";
import { openNotificationWithIcon } from "../../utils";
import { useFetchEvidenceViewer, useFetchSearchResult } from "../../hooks";
import { EvidenceViewerContainer } from "./EvidenceViewerContainer";
import { Modal } from "antd/lib";
import { CloseOutlined } from "@ant-design/icons";
import { EvidenceViewerProps } from "../../containers/modals/evidence-viewer";
import { useDispatch, useSelector } from "react-redux";
import { RootState, setCurrentPage } from "../../store";

const EvidenceViewer = (props: EvidenceViewerProps) => {
  const { handleClose, visibility } = props;
  const [searchParams] = useSearchParams();
  const { documentUuid, signedUrl, currentPage } = useSelector((state: RootState) => state.document)
  const { fetchSearchResults, searchLoading, searchResult } = useFetchSearchResult();
  const [pageSearchKey, setPageSearchKey] = useState<string | null>(null);
  const [searchKeywords, setSearchKeywords] = useState<string | null>(null);
  const dispatch = useDispatch();

  const { fileId, multipleFiles, tileType } = useMemo(() => {
    setSearchKeywords(searchParams.get("search"));
    return {
      fileId: documentUuid || "",
      initialSearchKeywords: searchParams.get("search"),
      multipleFiles: searchParams.get("multipleFiles"),
      tileType: searchParams.get("tile_type"),
      isMeasure: searchParams.get("isMeasure"),
      queryId: searchParams.get("queryId") || "",
    };
  }, [searchParams]);

  const {
    fetchPage,
    currPage,
    occurencesList,
    pageLoading,
    pageData,
    isTiffFile,
    setCurrPage,
    fetchSearchOccurences,
  } = useFetchEvidenceViewer({ searchKeywords, documentUuid: documentUuid, signedUrl });

  const handleSetCurrPage: React.Dispatch<React.SetStateAction<number>> = (pageOrUpdater) => {
    const newPage = typeof pageOrUpdater === "function" ? pageOrUpdater(currentPage) : pageOrUpdater;
    dispatch(setCurrentPage(newPage));
  };

  useLayoutEffect(() => {
    if (tileType) {
      const find = occurencesList.findIndex((ele: any) => ele.pageNumber === currentPage);
      if (find !== -1) {
        setPageSearchKey(occurencesList[find].content.slice(0, tileType === "lifestyle" ? 14 : 20));
      }
    }
  }, [currentPage, occurencesList, tileType]);

  useEffect(() => {
    const handlefetchSearchResults = async () => {
      try {
        if (multipleFiles === "true" && !searchResult.data) {
          await fetchSearchResults("?limit=1000&offset=0");
        }
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
      }
    };
    handlefetchSearchResults();
  }, [fetchSearchResults, multipleFiles, searchResult.data]);

  useEffect(() => {
    if (fileId) {
      fetchPage();
    }
  }, [fileId, currentPage]);

  useEffect(() => {
    if (((searchKeywords && searchKeywords.length > 0) || tileType) && fileId)
      fetchSearchOccurences(searchKeywords);
  }, [fetchSearchOccurences, fileId, searchKeywords, tileType]);

  return (
    <Modal
      className="autonomizeModal evidence-viewer-modal"
      centered
      footer={null}
      closable={false}
      open={visibility}
    >
      <div className="addClient-title no-bd-bt">
        <h1>{pageData.name ?  `Evidence Viewer : ${pageData.name}` : 'Evidence Viewer'}</h1>
        <CloseOutlined onClick={handleClose} style={{color : "white"}}/>  
      </div>
      <EvidenceViewerContainer className="addClient-content"
        {...{
          currentPage,
          evidenceContentLoading: pageLoading || searchLoading,
          fileId,
          pageData,
          pageSearchKey,
          isTiffFile,
          fileUrl: signedUrl,
          searchKeywords,
          setCurrPage:handleSetCurrPage,
        }}
      />
    </Modal>
  );
};

export default EvidenceViewer;