import React from "react";
import RenderProjectsInLists from "../../components/project-list/RenderProjectsInLists";
import RenderProjectInCards from "../../components/project-list/RenderProjectsInCards";
import { VIEW_STYLE } from "../../utils";
import { IProject } from "../../interfaces/project";

interface Props {
  viewStyle: VIEW_STYLE;
  sortedProjects: IProject[]; 
  loading: boolean;
  handleProjectClick: (project: any) => void; 
}

const ProjectsDataContainer = ({ viewStyle, sortedProjects, loading, handleProjectClick }: Props) => {
  return viewStyle === VIEW_STYLE.TILES ? (
    <RenderProjectInCards
      handleProjectClick={handleProjectClick}
      sortedProjects={sortedProjects}
      loading={loading}
    />
  ) : (
    <RenderProjectsInLists
      handleProjectClick={handleProjectClick}
      sortedProjects={sortedProjects}
      loading={loading}
    />
  );
};

export default ProjectsDataContainer;
