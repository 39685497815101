import { useCallback, useState } from "react";
import { STATUS_CODE, SortByType, openNotificationWithIcon } from "../utils";
import { projectService } from "../api/project";
import { useAuthorization } from "./useAuthorization";
import { updateCanFetchContainers } from "../store/file-folder-upload/slice";
import { useDispatch } from "react-redux";
import { IProject } from "../interfaces/project";

export const useProject = () => {
  const [loading, setLoading] = useState(false);
  const [deleteProjectLoading, setDeleteProjectLoading] = useState(false);
  const [projects, setProjects] = useState([] as IProject[]);
  const { setIsAuthorized } = useAuthorization();
  const dispatch = useDispatch();

  const fetchProjects = useCallback(
    async (search = "", sortBy: SortByType='createdAt') => {
      setLoading(true);
      try {
        const { data } = await projectService.getProjects(search, sortBy);
        setProjects(data.data);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err.response.data?.message, "error");
        }
      } finally {
        setLoading(false);
      }
    },
    [setIsAuthorized]
  );

  const deleteProject = useCallback(async (id: number) => {
    setDeleteProjectLoading(true);
    try {
      await projectService.deleteProject(id);
      openNotificationWithIcon("", "Project deleted successfully", "success");
    } catch (error: any) {
      openNotificationWithIcon("", error.response.data.message, "error");
    } finally {
      setDeleteProjectLoading(false);
    }
  }, []);

  const pinProject = useCallback(async (id: number,pinStatus:boolean| null | undefined) => {
    try {
      setLoading(true);
      await projectService.pinProject(id);
      dispatch(updateCanFetchContainers(true));
      setLoading(false);
      openNotificationWithIcon("", `Project ${pinStatus?'Unpinned':'Pinned'} Successfully`, "success");
    } catch (error: any) {
      openNotificationWithIcon("", error.response.data.message, "error");
    } finally {
      setDeleteProjectLoading(false);
    }
  }, []);

  return { fetchProjects, deleteProject, loading, deleteProjectLoading, pinProject, projects };
};
