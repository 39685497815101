import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IProjectDetails{
  guidelines:string;
  description:string;
  projectName:string
}
interface IDocumentSliceInitialState {
  documentIDForPagesStatus: string;
  pageNumber: string;
  documentDetails: IDocument;
  documentUuid: string;
  signedUrl: string;
  currentPage: number;
  projectDetails: IProjectDetails;
  currentChunkUUID: string;
}

export const documentInitialState: IDocumentSliceInitialState = {
  documentIDForPagesStatus: "",
  documentDetails: {} as IDocument,
  pageNumber: "",
  documentUuid: "",
  signedUrl: "",
  currentPage:1,
  projectDetails:{
    guidelines:"",
    description:"",
    projectName:"",
  },
  currentChunkUUID:"",
};

export const documentSlice = createSlice({
  name: "document",
  initialState: documentInitialState,
  reducers: {
    setDocumentIdFOrPagesStatus: (
      state: IDocumentSliceInitialState,
      { payload }: PayloadAction<string>
    ) => {
      state.documentIDForPagesStatus = payload;
    },
    setDocumentDetail: (
      state: IDocumentSliceInitialState,
      { payload }: PayloadAction<IDocument>
    ) => {
      state.documentDetails = { ...payload };
    },
    setPageNumberForMedicalEntities: (
      state: IDocumentSliceInitialState,
      { payload }: PayloadAction<string>
    ) => {
      state.pageNumber = payload;
    },
    setDocumentUuid: (state: IDocumentSliceInitialState, { payload }: PayloadAction<string>) => {
      state.documentUuid = payload;
    },
    setSignedUrl: (state: IDocumentSliceInitialState, { payload }: PayloadAction<string>) => {
      state.signedUrl = payload;
    },
    resetDocument: (state: IDocumentSliceInitialState) => {
      state = documentInitialState;
    },
    setCurrentPage: (state: IDocumentSliceInitialState, { payload }: PayloadAction<number>) => {
      state.currentPage = payload;
    },
    setCurrentChunkUUID: (state: IDocumentSliceInitialState, { payload }: PayloadAction<string>) => {
      state.currentChunkUUID = payload;
    },
    setProjectDetails: (state: IDocumentSliceInitialState, { payload }: PayloadAction<IProjectDetails>) => {
      state.projectDetails.guidelines = payload.guidelines;
      state.projectDetails.description = payload.description;
      state.projectDetails.projectName = payload.projectName
    },
  },
});

export const {
  setDocumentIdFOrPagesStatus,
  setDocumentDetail,
  setPageNumberForMedicalEntities,
  setDocumentUuid,
  resetDocument,
  setSignedUrl,
  setCurrentPage,
  setProjectDetails,
  setCurrentChunkUUID
} = documentSlice.actions;
export default documentSlice.reducer;
