import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IExportSummarySlice {
  guidelinesSummary: string[];
  patientSummary: string;
}

export const exportSummaryInitialState: IExportSummarySlice = {
    guidelinesSummary:[""],
    patientSummary:""
};

export const exportSummarySlice = createSlice({
  name: "exportSummary",
  initialState: exportSummaryInitialState,
  reducers: {
    setPatientsSummary: (
      state: IExportSummarySlice,
      { payload }: PayloadAction<string>
    ) => {
      state.patientSummary = payload;
    },
    setGuidelinesSummary: (
        state: IExportSummarySlice,
        { payload }: PayloadAction<string[]>
      ) => {
        state.guidelinesSummary = payload;
      },
  },
});

export const { setGuidelinesSummary,setPatientsSummary } = exportSummarySlice.actions;
export default exportSummarySlice.reducer;
