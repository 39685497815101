import { IRepsonseWrapper, apiClient } from ".";
import {
  ICreateProject,
  IEditProjectPayload,
  IProject,
  ProjectDetailResponse,
} from "../interfaces/project";
import { SortByType } from "../utils";

const getProjects = async (search = "", sortBy: SortByType) => {
  const res = await apiClient.get<IRepsonseWrapper<IProject[]>>(
    `/project/overview?isPriorAuth=true&searchTerm=${search}&sortBy=${sortBy}`
  );
  return res;
};

const getProject = async (id: number) => {
  const res = await apiClient.get<IRepsonseWrapper<ProjectDetailResponse>>(`project/${id}`);
  return res;
};

const getGenerativeAiModel = async () => {
  const res = await apiClient.get(`/generativeAiModel`);
  return res;
};

const getGuidelines = async () => {
  const res = await apiClient.get(`/reference`);
  return res;
};

const deleteProject = async (id: number) => {
  const res = await apiClient.delete<IRepsonseWrapper<IProject>>(`/project/${id}`);
  return res;
};

const pinProject = async (id: number) => {
  const res = await apiClient.patch<IRepsonseWrapper<IProject>>(`/project/${id}/pin-project`);
  return res;
};

const createProject = async (payload: ICreateProject) => {
  const qs = payload.excludeBolbStorage
    ? `?exclude_blob_storage=${payload.excludeBolbStorage}`
    : "";
  const res = await apiClient.post<IRepsonseWrapper<IProject>>(`/project${qs}`, payload);
  return res;
};

const editProject = async (id: number, payload: IEditProjectPayload) => {
  const res = await apiClient.put<IRepsonseWrapper<IProject>>(`/project/${id}`, payload);
  return res;
};

export const projectService = {
  getProjects,
  getProject,
  deleteProject,
  createProject,
  getGenerativeAiModel,
  getGuidelines,
  pinProject,
  editProject,
};
