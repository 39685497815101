export const SEARCH_KEYWORD_DELIMINATOR = ",";

export const CHECKBOX_DATA = `We've identified records of the Patient across call logs, filed claims and past
            Authorisations. Please select any of the sources below to generate relevant case
            summaries that enable in compliance`;

export const GUIDELINES_CHECKBOXES = {
  CALL_CENTER_LOGS: "Call Center Logs",
  CLAIMS_HISTORY: "Claims History",
  AUTH_HISTORY: "Auth History",
};

export type SortByType = 'name' | 'createdAt' | '-name' | '-createdAt';


export const SORT_OPTIONS = [
  {
    value: "name",
    label: "Name A-Z",
  },
  {
    value: "-name",
    label: "Name Z-A",
  },
  {
    value: "-createdAt",
    label: "Recent first",
  },
  {
    value: "createdAt",
    label: "Recent last",
  },
];
