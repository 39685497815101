import React from "react";
import { Modal } from "antd";
import { Button } from "antd";
import { DeleteConfirmationProps } from ".";
import { CloseOutlined } from "@ant-design/icons";
import "../modal.scss";

const DeleteConfirmation = ({ visibility, handleClose, handleDelete }: DeleteConfirmationProps) => {
  const handleDeleteConfirmation = () => {
    handleDelete();
    handleClose();
  };
  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      title="Delete MOdal"
      visible={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="deleteConfirmation tc">
        <h1>Are you sure?</h1>
        <p>
          Do you really want to delete this project ?<br /> This process cannot be undone.
        </p>
        <div
          className="addClient-content flex jcc gp"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Button type="primary" className="outline" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="primary" className="outline" onClick={handleDeleteConfirmation}>
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmation;
