import React from 'react';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib';
import "./radio-toggle.scss";

interface RadioToggleProps {
  options: { label: string; value: string }[];
  value: string;
  onChange: ((e: RadioChangeEvent) => void);
}

const RadioToggle: React.FC<RadioToggleProps> = ({ options, value, onChange }) => {
  return (
    <Radio.Group value={value} onChange={onChange}>
      {options.map((option, index) => (
        <Radio.Button key={index} value={option.value}>
          {option.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default RadioToggle;