export enum THEME {
  LIGHT = "light-theme",
  DARK = "dark-theme",
}

export enum DOCUMENT_STATUS {
  PROCESSING = "PROCESSING",
  READY = "READY",
  FAILED = "FAILED",
  READY_WITH_SUMMARY = "READY_WITH_SUMMARY",
}

export enum REVIEW_STATUS {
  NOT_REVIEWED = "NOT_REVIEWED",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  REQUESTED_FOR_MORE_INFO = "REQUESTED_MORE_INFO",
  FORWARDED_FOR_REVIEW = "FORWARDED_FOR_REVIEW",
}

export enum STATUS_CODE {
  FORBIDDEN = 403,
}

export enum MODAL_TYPE {
  ADD_PROJECT = "addProject",
  EXPAND_PDF = "expandPdf",
  EVIDENCE_VIEWER = "evidenceViewer",
  FEEDBACK = "feedback",
  DELETE_CONFIRMATION = 'deleteConfirmation',
}

export enum VIEW_STYLE {
  TILES,
  LIST,
}