import React from "react";
import { ColumnsType } from "antd/es/table";
import { IProject } from "../../interfaces/project";
import { Table } from "antd/lib";
import { getLocalDateTimeStringFromISO } from "../../utils";
import { ProjectActions } from "../../pages/dashboard/ProjectActions";
import { Loading } from "../Loading";
import { Tooltip } from "antd";
import RenderDescription from "./RenderDescription";

interface Props {
  handleProjectClick: (project: IProject) => void;
  sortedProjects: IProject[];
  loading: boolean;
}

const RenderProjectsInLists: React.FC<Props> = ({
  handleProjectClick,
  sortedProjects,
  loading,
}: Props) => {
  if (loading) {
    return <Loading />;
  }
  if (sortedProjects.length === 0) {
    return (
      <div className="projects-not-found">
        <p className="text">No Projects Found</p>
      </div>
    );
  }
  const getColumns: ColumnsType<IProject> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, item) => (
        <div onClick={() => handleProjectClick(item)} className="link-color">
          {text}
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text: string, item) => <RenderDescription description={text} projectId={item.id} />,
    },
    {
      title: "Guidelines",
      dataIndex: "guidelines",
      key: "guidelines",
      render: (text: string) => <div>{text}</div> 
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => getLocalDateTimeStringFromISO(text),
      sorter: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    },
    {
      title: "Updated On",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => getLocalDateTimeStringFromISO(text),
      sorter: (a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
    },
    {
      title: "Action",
      key: "action",
      render: (_, item) => (
        <div className="flex gp link-color">
          <ProjectActions project={item} />
        </div>
      ),
    },
  ];

  const data = sortedProjects.map((item: IProject) => ({
    key: item.id,
    ...item,
  }));

  return (
    <div className="project-list basic-detail-content">
      <Table
        columns={getColumns}
        dataSource={data}
        pagination={false}
        onRow={(record) => ({
          // onClick: () => handleClick(record),
        })}
        rowClassName={"cursor-pointer"}
      />
    </div>
  );
};

export default RenderProjectsInLists;
