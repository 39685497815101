import { CloseOutlined } from "@ant-design/icons";
import { Modal, Button } from "antd";
import React, { useState } from "react";
import { PositiveFeedbackProps } from ".";
import TextArea from "antd/lib/input/TextArea";
import { openNotificationWithIcon } from "../../../utils";
import { ThumbsDown, ThumbsUp } from "../../../assets/icons";
import { feedbackService } from "../../../api/feedback";
import { NegativeFeedback } from "./NegativeFeedback";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export const FeedbackForm = ({
  feedbackType,
  summaryType,
  summaryId,
  visibility,
  handleClose,
}: 
PositiveFeedbackProps) => {
  const [feedback, setFeedback] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { documentUuid } = useSelector((state: RootState) => state.document);

  const handleSubmit = async () => {
    handleClose();
    try {
      setIsLoading(true);
      const { data } = await feedbackService.addFeedback({
        feedback,
        feedbackType: feedbackType === 1 ? true: false,
        documentUUID:documentUuid,
        summaryId
      });
      if (data.data.message === "Feedback saved successfully") {
        openNotificationWithIcon("", data.data.message, "success");
        handleClose();
      }
    } catch (e: any) {
      openNotificationWithIcon("", e.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      visible={visibility}
      footer={null}
      title={"Create Folder"}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title no-bd-bt">
        <h1 className="mb-0">Feedback</h1>
      </div>
      <div className="addClient-content no-pd-tp">
        <div className="flex gp-10 aic m-b">
          {feedbackType === 1 ? <ThumbsUp /> : <ThumbsDown />}

          <h4 className="mb-0">Provide additional feedback for {summaryType}</h4>
        </div>
        {feedbackType === 1 ? (
          <TextArea
            placeholder="What do you like about the response?"
            onChange={(e) => {
              setFeedback(e.target.value);
            }}
            value={feedback}
            rows={5}
          />
        ) : (
          <NegativeFeedback {...{ feedback, setFeedback }} />
        )}

        <div className="tr m-t">
          <Button loading={isLoading} className="fill" onClick={handleSubmit}>
            Submit Feedback
          </Button>
        </div>
      </div>
    </Modal>
  );
};
