import React, { useState } from "react";
import { PageWrapper } from "../containers";
import EditProjectContainer from "../containers/edit-project-container/EditProjectContainer";

export const EditProject = () => {
  const [isLoading, setIsLoading] = useState(false);

  return <PageWrapper loading={isLoading} pageContainerChildren={<EditProjectContainer />} />;
};

export default EditProject;
