import React, { useState } from "react";
import { Progress } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import { AppPagination } from "../app-pagination/AppPagination";
import "./pdf-content.scss";
import { useDispatch } from "react-redux";
import { openModal } from "../../store";
import { MODAL_TYPE } from "../../utils";
import { updateTargetFileFolder } from "../../store/file-folder-upload/slice";
import { setSignedUrl, setCurrentPage } from "../../store/document/slice";
import { FullscreenOutlined } from "@ant-design/icons";
import { Tooltip } from "antd/lib";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {
  pageInfo?: {
    width?: number;
    height?: number;
    scale?: number;
  };
  displayPagination?: boolean;
  currPage: number;
  setCurrPage?: (page: number) => void;
  pdf: any;
  className?: string;
  noExpand?: boolean;
  sourcePopup?: boolean;
  fileName?:string
}

export const PdfViewer = (props: Props) => {
  const { pdf, pageInfo, displayPagination, currPage, setCurrPage ,sourcePopup,fileName} = props;
  const [totalPages, setTotalPages] = useState(0);
  const [loadSuccess, setLoadSuccess] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState({
    loaded: 0,
    total: 100,
  });
  const dispatch = useDispatch();
  const displayBottomPagination = displayPagination && loadSuccess;

  const onDocumentLoadSuccess = (params: any) => {
    const { numPages } = params;
    setTotalPages(numPages);
    setLoadSuccess(true);
  };
  const handleClickExpand = () => {
    dispatch(setCurrentPage(currPage));
    dispatch(setSignedUrl(props.pdf));
    dispatch(openModal(MODAL_TYPE.EVIDENCE_VIEWER));
  };
  const handlePageChange = (page: number) => {
    setCurrPage && setCurrPage(page);
    dispatch(setCurrentPage(page));
  };

  return (
    <div className={`e-pdf e-content ${props.className}`}>
      {displayBottomPagination && (
        <div className="flex-center e-pdf-pagination">
           <h5 className="file-name">{fileName}</h5>
           <div className="pagination-container">
          <AppPagination
            total={totalPages * 10}
            simple
            current={currPage}
            onChange={handlePageChange}
          />

          {!sourcePopup && !props.noExpand ? (
            <Tooltip title="Evidence Viewer">
              <FullscreenOutlined className="expand-icon" onClick={handleClickExpand} />
            </Tooltip>
          ) : (
            ""
          )}
        </div>
        </div>
      )}
      <Document
        className={`pdf ${props.noExpand ? "expanded" : ""}`}
        file={pdf}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={
          <Progress percent={Math.ceil((loadingProgress.loaded * 100) / loadingProgress.total)} />
        }
        onLoadProgress={(e) => setLoadingProgress(e)}
      >
        <Page
          pageNumber={currPage}
          height={pageInfo?.height}
          width={pageInfo?.width || 500}
          scale={pageInfo?.scale}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document>
    </div>
  );
};
