import { apiClient } from ".";

interface IAddFeddbackRequest {
  logId: number;
  feedback: string;
  feedbackType: string;
  qaId: string;
}

interface IAddFeddbackResponseData {
  Id: number;
  PatientId: number;
  Question: string;
  Answer: string;
  Feedback: string;
  FeedbackType: boolean;
  CreatedBy: number;
  updatedAt: string;
  createdAt: string;
}

interface IAddFeddbackResponse {
  message: string;
  data: { message: string };
}

interface IFeedbackRequest{
  feedback:string;
  feedbackType:boolean;
  documentUUID:string | undefined;
  summaryId:number;
}


const addQaFeedback = async (obj: IAddFeddbackRequest) => {
  const res = await apiClient.post<IAddFeddbackResponse>(`/feedback/qa`, obj);
  return res;
};

const addFeedback = async (obj: IFeedbackRequest) => {
  const res = await apiClient.post<IAddFeddbackResponse>(`/feedback`, obj);
  return res;
};

export const feedbackService = {
  addQaFeedback,
  addFeedback
};
